import Link from "next/link";
import React, { useState } from "react";

import { ArticleImage } from "@/components/ui/ArticleImage";
import { NarrativeSummary } from "@/model/api";
import { getArticleUrl, getTickerUrl } from "@/util/article";

interface Props {
  list: NarrativeSummary[];
}

const MarketNewsMobile: React.FC<Props> = ({ list }) => {
  const [visibleItems, setVisibleItems] = useState(0);

  const loadMore = () => {
    setVisibleItems((prevItems) => Math.min(prevItems + 5, list.length));
  };

  return (
    <div className="bg-white mb-4 border-b-dolphin-silver border-t pt-4">
      {visibleItems > 0 && (
        <ul className="flex flex-col w-full justify-between space-y-4">
          {list
            .slice(0, visibleItems)
            .map((narrative: NarrativeSummary, index) => {
              const articleLink = getArticleUrl(narrative);

              return (
                <li
                  key={`${narrative.Id}-${narrative.Date}-${index}`}
                  className="scale-100 transition-all duration-150 border-t-dolphin-silver border-b last:border-b-0 bg-white rounded-none relative pb-4 w-full"
                >
                  <div className="w-full flex space-x-2 justify-between items-start ">
                    <div className="bg-gradient-to-b from-zinc-400 via-zinc-500 to-black w-20 relative rounded-md flex-shrink-0 flex-grow-0 my-0 max-h-20 min-h-20 h-20 ">
                      <div
                        className="absolute w-full h-full sm:px-0 mix-blend-overlay"
                        id={narrative.Id}
                      >
                        <ArticleImage
                          ticker={narrative.Ticker}
                          narrative={narrative}
                        />
                      </div>
                    </div>
                    <div className="flex flex-col h-full w-full pl-2">
                      <Link
                        href={getTickerUrl(narrative.Ticker)}
                        className="text-fi-red text-xs sm:text-sm font-bold cursor-pointer hover:text-blue-light pb-1"
                        passHref
                      >
                        {narrative.Ticker} -{" "}
                        <span className="text-gray-500 font-normal hover:text-blue-light">
                          {narrative.CompanyName}
                        </span>
                      </Link>
                      <Link
                        href={articleLink}
                        className="text-xs sm:text-sm font-semibold leading-normal text-fi-black cursor-pointer hover:text-fi-red"
                        passHref
                      >
                        {narrative.Headline}
                      </Link>
                    </div>
                  </div>
                </li>
              );
            })}
        </ul>
      )}
      {visibleItems < list.length && (
        <button
          onClick={loadMore}
          className="w-full mt-4 bg-gray-900 text-white py-3 px-4 rounded-md hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-gray-600 focus:ring-opacity-50 transition duration-150 ease-in-out"
        >
          Load More Market News
        </button>
      )}
    </div>
  );
};

export default MarketNewsMobile;
